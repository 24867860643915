import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 4×4\\@80%1RM`}</p>
    <p>{`DB Pullover 4×4`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 500M Ski Erg`}</p>
    <p>{`5 Rounds of:`}</p>
    <p>{`10-Ring Dips`}</p>
    <p>{`10-DB Renegade Rows, Right Arm (35/20)(RX+ 50/35)`}</p>
    <p>{`10-DB Renegade Rows, Left Arm`}</p>
    <p>{`10-DB Push Press, Right Arm`}</p>
    <p>{`10-DB Push Press, Left Arm`}</p>
    <p>{`Cash Out: 500M Ski Erg`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      